body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textButton {
  border: none;
  background-color: #00000000;
}
.textButton:hover {
  text-decoration: underline;
}

.input-container {
  position: relative;
  /* float: left; */
}

.input-container:after {
  position: absolute;
  right: 8px;
  top: 0;
  content: 'ARCH';
}

.wide {
  width: 100%;
}